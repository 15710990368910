div.week {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    width: calc(100vw - 335px);
}

div.info {
    min-width: max-content;
}

div.title {
    font-weight: bold;
}

div.besides {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

div.box {
    gap: 0;
    flex: 0 0 calc(20% - 3px);
}

div.boxInside {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.boxBackground{
    background-color: #B6F2E4;
    justify-content: flex-end;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 5px;
    overflow: hidden;
    margin: 10px 5px;
}

div.text {
    padding: 1.8rem;
    color: #fafafa;
}

div.avatar {
    max-width: 50px;
    border-radius: 50%;
}

div.days{
    display: contents;
}

div.header {
    text-align: center;
    font-weight: bold;
}

div.card
{
    overflow: visible;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

div.flex{
    display: flex;
}